import React, { useState, useEffect } from "react";
import CustomPagination from "./pagination";
import Swal from 'sweetalert2';
import { withPrefix } from "gatsby";
import ProductDetail from "./product-detail";
import Backdrop from '@mui/material/Backdrop';
import { navigate } from "gatsby";
import { useDispatch, useSelector } from 'react-redux';
import { getStoreCollectionProducts, bulkUpdateStoreProductsStatus, bulkUpdateStoreProductsPrice, bulkDeleteStoreProducts, updateStoreCollection, getResetAllFlags, selectStoresState } from "../../sagas/stores/storesSlice";
import { BulkEditModal } from "./product-detail";
import OriginArtModal from "./origin-art-modal";

export default function ProductList({ store, collection, setPageState, setIsCollectionsReloadRequired }) {
  const dispatch = useDispatch();
  const { 
    isFetchingStoreCollectionProductsSuccess,
    isFetchingStoreCollectionProductsFailed,
    StoreCollectionProducts,
    StoreCollectionCarousels,
    isUpdatingStoreCollectionSuccess,
    UpdateStoreCollectionResponse,
    isBulkUpdatingStoreProductsStatusSuccess,
    isBulkUpdatingStoreProductsStatusFailed,
    isBulkUpdatingStoreProductsPriceSuccess,
    isBulkUpdatingStoreProductsPriceFailed,
    isBulkDeletingStoreProductsSuccess,
    isBulkDeletingStoreProductsFailed,
  } = useSelector(selectStoresState);

  const [products, setProducts] = useState([]);
  const [collectionTitle, setCollectionTitle] = useState(collection.node.title);

  //Pagination code: start
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [count, setCount] = useState(1);

  const fetchCollectionProducts = () => {
    const tagId = collection?.node?.ruleSet?.rules?.find((rule) => rule.relation === 'EQUALS')?.condition;
    if (store?.id && tagId) {
      setProducts([]);
      setIsLoading(true);
      dispatch({
        type: getStoreCollectionProducts.type,
        payload: {
          storeId: store.id,
          tagId,
          rangeSize: collection.node.productsCount,
        }
      });
    }
  }

  useEffect(() => {
    if (isFetchingStoreCollectionProductsSuccess) {
      const products = StoreCollectionProducts.map((e) => ({...e.node, storeId: e.storeId}));
      setProducts(products.filter((p) => !!p.featuredImage?.url));
      setIsLoading(false);
      dispatch(getResetAllFlags());
    } else if (isFetchingStoreCollectionProductsFailed) {
      setIsLoading(false);
      dispatch(getResetAllFlags());
    }
  }, [isFetchingStoreCollectionProductsSuccess, isFetchingStoreCollectionProductsFailed, StoreCollectionProducts])

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setPage(1);
    setPerPage(10);
    setCount(1);

    fetchCollectionProducts();
  }, [collection, store])

  useEffect(() => {
    const newCount = Math.ceil(products.length / perPage);
    setCount(newCount);
    if (page > 1 && newCount > 0 && page > newCount) setPage(newCount);
  }, [products, perPage])

  const handlePaginationChange = async (event, newPage) => {
    event.preventDefault();
    setPage(newPage);
  };

  const handlePerPageChange = (e) => {
    const perPageVal = +e.target.value;
    setPerPage(perPageVal);
    setPage(1);
  }
  //Pagination code: end

  const [selectedProduct, setSelectedProduct] = useState();
  const handleClickProduct = (product, e) => {
    e.preventDefault();
    setSelectedProduct(product);
    setShowProductDetail(true);
  }

  const [selectedProducts, setSelectedProducts] = useState([]);
  const [allChecked, setAllChecked] = useState(false);
  const handleCheckAll = (e) => {
    if (e.target.checked) {
      setSelectedProducts(products?.slice((page-1)*perPage, page*perPage));
      setAllChecked(true);
    } else {
      setSelectedProducts([]);
      setAllChecked(false);
    }
  }

  const handleCheckProduct = (product, e) => {
    if (e.target.checked) {
      setSelectedProducts([...selectedProducts, product]);
    } else {
      setSelectedProducts((products) => products.filter((p) => p.id !== product.id));
    }
    setAllChecked(false);
  }

  const removeSelectedProducts = () => {
    if (selectedProducts.length > 0) {
      Swal.fire({
        title: 'Are you sure?',
        html: '<div>By continuing you will permanently remove these products. <br/>This action cannot be undone.</div>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Confirm'
      }).then(async(result) => {
        if (result.isConfirmed) {
          setIsLoading(true);
          const productIds = selectedProducts.map((p) => p.id.replace('gid://shopify/Product/', ''));
          const data = {
            storeId: store.id,
            collectionId: collection.node.id.replace('gid://shopify/Collection/', ''),
            collectionTagId: collection.node.ruleSet.rules?.[0].condition,
            productIds,
          }
          dispatch({
            type: bulkDeleteStoreProducts.type,
            payload: data,
          });
        }
      })
    } else {
      Swal.fire({
        icon: 'warning',
        text: 'Please select products to remove!',
      })
    }
  }

  useEffect(() => {
    if (isBulkDeletingStoreProductsSuccess) {
      setProducts((products) => {
        return products.filter((p) => {
          return !selectedProducts.some((sp) => sp.id === p.id);
        })
      });

      setSelectedProducts([]);
      setAllChecked(false);
      setIsCollectionsReloadRequired(true);
      setIsLoading(false);
      dispatch(getResetAllFlags());
    } else if (isBulkDeletingStoreProductsFailed) {
      Swal.fire({
        icon: 'error',
        text: 'Something went wrong, please try again later.',
      })
      setIsLoading(false);
      dispatch(getResetAllFlags());
    }
  }, [isBulkDeletingStoreProductsSuccess, isBulkDeletingStoreProductsFailed])

  const [updateProductAction, setUpdateProductAction] = React.useState('');

  const deactivateSelectedProducts = () => {
    if (selectedProducts.length > 0) {
      const productIds = selectedProducts.filter((p) => p.status.toLowerCase() === 'active').map((p) => p.id.replace('gid://shopify/Product/', ''));
      if (productIds.length === 0) {
        Swal.fire({
          text: 'No items to be deactivated.',
          icon: 'info',
          showCancelButton: false,
          confirmButtonText: 'OK'
        })
        return;
      }

      Swal.fire({
        title: 'Are you sure?',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Confirm'
      }).then(async(result) => {
        if (result.isConfirmed) {
          Swal.fire({
            icon: 'info',
            text: "Thank you for confirming your changes! We're now processing your request and updating your store. Please note that this may take a few minutes to complete.",
          })
          
          setIsLoading(true);
          setUpdateProductAction('deactivate');
          const data = {
            storeId: store.id,
            productIds,
            action: 'deactivate',
          }
          dispatch({
            type: bulkUpdateStoreProductsStatus.type,
            payload: data,
          });
        }
      })
    } else {
      Swal.fire({
        icon: 'warning',
        text: 'Please select products to deactivate!',
      })
    }
  }

  const activateSelectedProducts = () => {
    if (selectedProducts.length > 0) {
      const productIds = selectedProducts.filter((p) => p.status.toLowerCase() === 'draft').map((p) => p.id.replace('gid://shopify/Product/', ''));
      if (productIds.length === 0) {
        Swal.fire({
          text: 'No items to be activated.',
          icon: 'info',
          showCancelButton: false,
          confirmButtonText: 'OK'
        })
        return;
      }

      Swal.fire({
        title: 'Are you sure?',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Confirm'
      }).then(async(result) => {
        if (result.isConfirmed) {
          Swal.fire({
            icon: 'info',
            text: "Thank you for confirming your changes! We're now processing your request and updating your store. Please note that this may take a few minutes to complete.",
          })

          setIsLoading(true);
          setUpdateProductAction('activate');
          const data = {
            storeId: store.id,
            productIds,
            action: 'activate',
          }
          dispatch({
            type: bulkUpdateStoreProductsStatus.type,
            payload: data,
          });
        }
      })
    } else {
      Swal.fire({
        icon: 'warning',
        text: 'Please select products to activate!',
      })
    }
  }

  useEffect(() => {
    if (isBulkUpdatingStoreProductsStatusSuccess) {
      if (updateProductAction) {
        const label = updateProductAction === 'deactivate' ? 'DRAFT' : 'ACTIVE';

        setProducts((products) => {
          return products.map((p) => {
            return {
              ...p,
              status: selectedProducts.some((sp) => sp.id === p.id) ? label : p.status,
            }
          })
        });
        setUpdateProductAction('');
      }

      setSelectedProducts([]);
      setAllChecked(false);
      setIsLoading(false);
      dispatch(getResetAllFlags());
    } else if (isBulkUpdatingStoreProductsStatusFailed) {
      Swal.fire({
        icon: 'error',
        text: 'Something went wrong, please try again later.',
      })
      setIsLoading(false);
      dispatch(getResetAllFlags());
    }
  }, [isBulkUpdatingStoreProductsStatusSuccess, isBulkUpdatingStoreProductsStatusFailed, updateProductAction])

  const [showProductDetail, setShowProductDetail] = useState(false);
  const [isProductsReloadRequired, setIsProductsReloadRequired] = useState(false);
  useEffect(() => {
    if (isProductsReloadRequired) {
      setIsProductsReloadRequired(false);
      fetchCollectionProducts();
    }
  }, [isProductsReloadRequired])

  const editCollection = async() => {
    const inputStyle = `
      height: 54px;
      font-family: "Roboto Mono", monospace;
      font-weight: 400;
      font-size: 14px;
      color: #000000;
      line-height: 22px;
      border: 1px solid #5757FF;
      border-radius: 4px;
      padding: 16px;
      width: 320px;
    `;

    const { value: title } = await Swal.fire({
      title: 'Settings',
      html: `<div><p>Edit Collection Name</p><input id="swal-input" style='${inputStyle}' value="${collectionTitle}"></div>`,
      inputPlaceholder: 'Enter new Name',
      focusConfirm: false,
      preConfirm: () => {
        return document.getElementById('swal-input').value;
      },
      showCancelButton: true,
      confirmButtonText: 'Confirm',
    })
    
    if (title) {
      dispatch({
        type: updateStoreCollection.type,
        payload: {
          storeId: store.id,
          collectionId: collection.node.id.replace('gid://shopify/Collection/', ''),
          data: { title },
        }
      });
    }
  }

  useEffect(() => {
    if (isUpdatingStoreCollectionSuccess) {
      setIsCollectionsReloadRequired(true);
      setCollectionTitle(UpdateStoreCollectionResponse.title);
      dispatch(getResetAllFlags());
    }
  }, [isUpdatingStoreCollectionSuccess, UpdateStoreCollectionResponse])

  const [openSortList, setOpenSortList] = React.useState(false);
  const handleCloseSortList = () => {
    setOpenSortList(false);
  };
  const handleOpenSortList = () => {
    setOpenSortList(true);
  };
  const handleClickSort = (val) => {
    setOpenSortList(false);

    switch (val) {
      case 'newest':
        setProducts(products.sort((a, b) => {
          if(new Date(a.updatedAt) < new Date(b.updatedAt)) return 1;
          if(new Date(a.updatedAt) > new Date(b.updatedAt)) return -1;
          return 0;
        }));
        break;

      case 'oldest':
        setProducts(products.sort((a, b) => {
          if(new Date(a.updatedAt) < new Date(b.updatedAt)) return -1;
          if(new Date(a.updatedAt) > new Date(b.updatedAt)) return 1;
          return 0;
        }));
        break;

      case 'a-z':
        setProducts(products.sort((a, b) => {
          if(a.title < b.title) return -1;
          if(a.title > b.title) return 1;
          return 0;
        }));
        break;

      case 'z-a':
        setProducts(products.sort((a, b) => {
          if(a.title < b.title) return 1;
          if(a.title > b.title) return -1;
          return 0;
        }));
        break;
    
      default:
        break;
    }
  }

  const addProduct = () => {
    navigate("/my-creations");
  }

  // Bulk edit: start
  const [openBulkEditModal, setOpenBulkEditModal] = useState(false);
  const bulkEdit = () => {
    if (selectedProducts.length > 0) {
      setOpenBulkEditModal(true);
    } else {
      Swal.fire({
        icon: 'warning',
        text: 'Please select products to bulk edit!',
      })
    }
  }
  const handleCloseBulkEdit = () => {
    setOpenBulkEditModal(false);
  }
  const handleConfirmBulkEdit = (value, mode) => {
    setOpenBulkEditModal(false);

    Swal.fire({
      title: 'Are you sure?',
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: 'Confirm'
    }).then(async(result) => {
      if (result.isConfirmed) {
        Swal.fire({
          icon: 'info',
          text: "Thank you for confirming your changes! We're now processing your request and updating your store. Please note that this may take a few minutes to complete.",
        });

        setIsLoading(true);

        const productIds = selectedProducts.map((p) => p.id.replace('gid://shopify/Product/', ''));
        const data = {
          storeId: store.id,
          productIds,
          bulkEditOption: { mode, value },
        }
        dispatch({
          type: bulkUpdateStoreProductsPrice.type,
          payload: data,
        });
      }
    })
  }
  useEffect(() => {
    if (isBulkUpdatingStoreProductsPriceSuccess) {
      setIsLoading(false);
      setSelectedProducts([]);
      setAllChecked(false);
      dispatch(getResetAllFlags());
    } else if (isBulkUpdatingStoreProductsPriceFailed) {
      setIsLoading(false);
      Swal.fire({
        icon: 'error',
        text: 'Something went wrong, please try again later.',
      })
      dispatch(getResetAllFlags());
    }
  }, [isBulkUpdatingStoreProductsPriceSuccess, isBulkUpdatingStoreProductsPriceFailed])
  // Bulk edit: end

  const [openOriginArtModal, setOpenOriginArtModal] = useState(false);
  const handleOpenOriginArt = () => {
    setOpenOriginArtModal(true);
  }
  const handleCloseOriginArt = () => {
    setOpenOriginArtModal(false);
  }

  return (
    <>
      {!showProductDetail ? (
        <div className="store-collection store-collection-products">
          <div className="breadcrumb-out">
            <div className="breadcrumb">
              <ul>
                <li><a href="#" onClick={() => setPageState('collection-list')}>Manage Collections</a></li>
                <li>{collectionTitle}</li>
              </ul>
            </div>
            <div className="btn-out"><a href="#" className="btn btn-blue" onClick={addProduct}>ADD PRODUCT</a></div>
          </div>

          {isLoading && (
            <img src={withPrefix("assets/img/green-little-balls.gif")} alt="loading" style={{ objectFit: 'contain', width: '100%', height: '400px' }} />
          )}

          {!isLoading && !!products?.length && (
            <>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div className="btns-out">
                <a href="#" className="btn" onClick={removeSelectedProducts}>Remove</a> 
                <a href="#" className="btn" onClick={deactivateSelectedProducts}>Off</a>
                <a href="#" className="btn" onClick={activateSelectedProducts}>On</a>
                <a href="#" className="btn" onClick={editCollection}>Settings</a>
                <a href="#" className="btn" onClick={bulkEdit}>Bulk Edit</a>
                {!!StoreCollectionCarousels?.length && <a href="#" className="btn" onClick={handleOpenOriginArt}>Origin Art</a>}
              </div>
              <div className="sort-box">
                <img src={withPrefix("assets/img/sort.svg")} alt="backDrop" onClick={handleOpenSortList} style={{ marginBottom: '16px', cursor: 'pointer' }} />
                {openSortList && (
                  <div className="sort-list">
                    <div className="title">Sort</div>
                    <button onClick={() => handleClickSort('newest')} className="confirm">NEWEST</button>
                    <button onClick={() => handleClickSort('oldest')} className="confirm">OLDEST</button>
                    <button onClick={() => handleClickSort('a-z')} className="confirm">A-Z</button>
                    <button onClick={() => handleClickSort('z-a')} className="confirm">Z-A</button>
                  </div>
                )}
              </div>
            </div>

              <div className="table-cont2">
                <div className="table-head">
                  <div className="table-col">
                    <div className="check-options">
                      <input name="checkbox0" id="checkbox0" type="checkbox" className="styled" checked={allChecked} onChange={handleCheckAll} />
                      <label htmlFor="checkbox0"><span className="sr-only">CheckBox</span></label>
                    </div>
                  </div>
                  <div className="table-col">
                    <div className="sort">Product</div>
                  </div>
                  <div className="table-col">
                    <div className="txt-sts">Status</div>
                  </div>
                </div>

                <div className="table-body">
                  {products?.slice((page-1)*perPage, page*perPage).map((product, index) => (
                    <div className="table-row" key={index}>
                      <a href="#" className="box" onClick={(e) => handleClickProduct(product, e)}>
                        <div className="table-col">
                          <div className="check-options" onClick={(e) => e.stopPropagation()}>
                            <input name={`checkbox${index+1}`} id={`checkbox${index+1}`} type="checkbox" checked={selectedProducts.some((sp) => sp.id === product.id)} onChange={(e) => handleCheckProduct(product, e)} className="styled" />
                            <label htmlFor={`checkbox${index+1}`}><span className="sr-only">CheckBox</span></label>
                          </div>
                        </div>
                        <div className="table-col">
                          <div className="col-inn">
                            <div className="figure">
                              <img src={product.featuredImage.url} alt="collection-2" />
                            </div>
                            <div className="name">{product.title}</div>
                          </div>
                        </div>
                        <div className="table-col">
                          <div className="btn-sts" style={{ textTransform: 'capitalize', background: product.status === 'ACTIVE' ? '#68e0b1' : '#b8e9ef' }}>{product.status.toLowerCase() === 'draft' ? 'HIDDEN' : 'ACTIVE'}</div>
                        </div>
                      </a>
                    </div>
                  ))}
                </div>
              </div>

              <CustomPagination count={count} handleChange={handlePaginationChange} page={page} perPage={perPage} handlePerPageChange={handlePerPageChange} />        
            </>
          )}
        </div>
      ) : (
        <ProductDetail store={store} collection={collection} product={selectedProduct} setPageState={setPageState} setShowProductDetail={setShowProductDetail} setIsProductsReloadRequired={setIsProductsReloadRequired} />
      )}   

      <Backdrop
        sx={{ color: '#fff', zIndex: 99 }}
        open={openSortList}
        onClick={handleCloseSortList}
      >
      </Backdrop>

      <BulkEditModal
        open={openBulkEditModal}
        handleClose={handleCloseBulkEdit}
        handleConfirm={handleConfirmBulkEdit}
      />

      <OriginArtModal
        open={openOriginArtModal}
        handleClose={handleCloseOriginArt}
      />
    </>
  )
}
