import React, { useState, useEffect } from "react";

export default function DomainManagementContent({ store }) {

  const [domain, setDomain] = useState('');

  useEffect(() => {
    setDomain(store?.shopify_store_url ?? '');
  }, [store])

  const handleUpdate = () => {
    
  }

  return (
    <div className="domain-details">
      <h2>Domain Manager</h2>
      <h3>You do not have a domain setup.</h3>

      <div className="search-form">
        <label>Please define one below.</label>
        <form>
          <input type="text" name="domain" id="domain" placeholder="domain.com" value={domain} onChange={(e) => setDomain(e.target.value)}  />
          <input type="submit" name="save" id="save" className="btn btn-blue" value="Save" onClick={handleUpdate} />
        </form>
      </div>

      <div className="box">
        <h3>Pointing my domain:</h3>
        <p>In order to configure your domain, you must update its DNS.</p>
        <ul>
          <li>the Shopify IP address <span>23.227.38.65</span></li>
          <li>the <span>www</span> CNAME record <span>shops.myshopify.com</span></li>
        </ul>
      </div>
      <p>99Gens e-Commerce is powered by Shopify.</p>
      <p>You can have only one A record and one www CNAME record associated with your domain. If your
        domain already has an A record or www CNAME record, then you need to change these records to point
        to Shopify. You can have multiple CNAME records associated with your domain, but each one must
        have a unique name, such as www or shop.</p>
      <p>Any questions or concerns, ask <a href="#">support</a>.</p>
    </div>
  )
}
