import * as React from 'react';
import { useState, useEffect } from 'react';
import { withPrefix, navigate } from "gatsby";
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import { selectStoresState } from "../../sagas/stores/storesSlice";
import { getSavedCarousels, selectCarouselsState } from "../../sagas/carousels/carouselsSlice";


export default function OriginArtModal({ open, handleClose }) {
  const { StoreCollectionCarousels } = useSelector(selectStoresState);
  const { isGettingSavedCarouselsSuccess, isGettingSavedCarouselsFailed, SavedCarouselsDto } = useSelector(selectCarouselsState);

  const dispatch = useDispatch();

  const [carousels, setCarousels] = useState([]);
  const [clickedConfirmBtn, setClickedConfirmBtn] = useState(false);
  useEffect(() => {
    if (open) {
      setCarousels(StoreCollectionCarousels);
      setClickedConfirmBtn(false);
    }
  }, [StoreCollectionCarousels, open])

  const removeItem = (id) => {
    setCarousels((carousels) => {
      return carousels.filter((carousel) => carousel.id !== id);
    });
  }

  const handleConfirm = () => {
    if (carousels.length > 0) {
      setClickedConfirmBtn(true);

      dispatch({
        type: getSavedCarousels.type,
        payload: {
          perPage: 10,
          page: 1,
          ids: carousels.map((c) => c.id),
        }
      });
      setIsLoading(true);
    }

    handleClose();
  }

  const [isLoading, setIsLoading] = React.useState(false);
  useEffect(() => {
    if (clickedConfirmBtn) {
      if (isGettingSavedCarouselsSuccess) {
        const myCreations = typeof window !== "undefined" ? JSON.parse(localStorage.getItem('myCreations') || '[]') : [];
        SavedCarouselsDto.data.forEach((carousel) => {
          if (!myCreations.some((creation) => creation._id === carousel._id)) {
            myCreations.push(carousel);
          }
        })
        localStorage.setItem("myCreations", JSON.stringify(myCreations));
        localStorage.setItem("selectedCarouselUuids", JSON.stringify(carousels.map(c => c.carousel_uID)));

        setClickedConfirmBtn(false);
        navigate("/my-creations-view");
      } else if (isGettingSavedCarouselsFailed) {
        console.log("Unable to fetch carousels");
        setIsLoading(false);
      }
    }
  }, [clickedConfirmBtn, isGettingSavedCarouselsSuccess, isGettingSavedCarouselsFailed, SavedCarouselsDto, carousels])

  return (
    <>
      {isLoading ? (
        <div className="new_gif" style={{ display: "flex", top: 0, left: 0 }}>
          <img
            src={withPrefix("assets/img/green-little-balls.gif")}
            className="img-fluid mw_50"
            alt="loading"
          />
        </div>
      ) : (
        <Dialog onClose={handleClose} open={open}>
          <div className="choose-merch-modal">
            <div className="modal" id="select-merch" role="dialog" onClick={handleClose}>
              <div className="modal-dialog modal-dialog-centered" role="document">

                <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                  <div className="product-details">
                    <div className="close" data-bs-dismiss="modal" onClick={handleClose}>
                      <img src={withPrefix("assets/img/icon-close-black.svg")} alt="icon-close-white" />
                    </div>

                    <div className="heading-top">
                      <h2>Origin Art</h2>
                      <p>
                        {carousels.length === 0 ? 'No Original Artwork has ' : carousels.length === 1 ? `${carousels.length} Original Artwork has ` : `${carousels.length} Original Artworks have `} 
                        been found in this collection.
                      </p>
                    </div>

                    <div className="product-img-out">
                      <div className="product-img">
                        <div className="product-out">
                          <div className="article-wrap">

                            {carousels.map((carousel, index) => (
                              <div className="article" key={index}>
                                <div className="box">
                                  <ImageItem item={carousel} />

                                  <div className="icon-close">
                                    <a onClick={() => removeItem(carousel.id)} className="far fa-times"></a>
                                  </div>
                                </div>
                              </div>
                            ))}

                          </div>
                        </div>

                        <div className="bottom-btns">
                          <div className="btn-out">
                            <a onClick={handleClose} className="btn">Cancel</a>
                          </div>
                          <div className="btn-out">
                            <a onClick={handleConfirm} className="btn btn-blue">Confirm</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </Dialog>      
      )}
    </>
  )
}

const ImageItem = ({ item }) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <div className="figure" style={{ position: 'relative', paddingTop: '100%' }}>
      <img 
        src={`${item.thumbnail}?version=240`} 
        alt={item.id} 
        style={{ 
          opacity: imageLoaded ? 1: 0, 
          position: 'absolute',
          top: 0,
          left: 0,
        }} 
        onLoad={() => setImageLoaded(true)} 
      />
      {!imageLoaded && (
        <img 
          src={withPrefix("assets/img/green-little-balls.gif")} 
          alt={item.id} 
          style={{ 
            opacity: imageLoaded ? 0: 1, 
            position: 'absolute',
            top: 0,
            left: 0,
          }}
        />
      )}
    </div>
  )
}

