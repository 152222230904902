import React, { useState, useEffect } from "react";
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import { updateStore, selectStoresState } from "../../sagas/stores/storesSlice";

export default function DetailsContent({ store, setIsStoresReloadRequired }) {
  const dispatch = useDispatch();
  const { isUpdatingStore, isUpdatingStoreSuccess, isUpdatingStoreFailed } = useSelector(selectStoresState);

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  useEffect(() => {
    setTitle(store?.store_title ?? '');
    setDescription(store?.store_description ?? '');
  }, [store])

  const handleUpdate = async() => {
    if (!title) {
      Swal.fire({
        icon: 'error',
        text: 'Title cannot be blank.',
      });
      return;
    }

    const data = {
      storeId: store.id,
      payload: {
        storeTitle: title,
        storeDescription: description,
      },
    };
    dispatch({
      type: updateStore.type,
      payload: data,
    });
  }

  useEffect(() => {
    if (isUpdatingStoreSuccess) {
      setIsStoresReloadRequired(true);
      Swal.fire({
        icon: 'success',
        text: 'Updated successfully',
        timer: 1500,
      });
    } else if (isUpdatingStoreFailed) {
      Swal.fire({
        icon: 'error',
        text: 'Something went wrong, please try again later.',
      });
    }
  }, [isUpdatingStore, isUpdatingStoreSuccess, isUpdatingStoreFailed])

  return (
    <div className="store-details">
      <div className="form-field">
        <form action="#" method="post">
          <h2>Edit Details</h2>
          <ul>
            <li>
              <label>Store Title</label>
              <div className="input-with-txt">
                <input id="p-name" name="p-name" type="text" placeholder="Store Title" value={title} onChange={(e) => setTitle(e.target.value)} />
                <div className="max-char">Max Char. 25</div>
              </div>
            </li>

            <li>
              <label>Store Description</label>
              <div className="input-with-txt">
                <textarea id="message" name="message" placeholder="Store Description" value={description} onChange={(e) => setDescription(e.target.value)} ></textarea>
                <div className="max-char">Max Char. 255</div>
              </div>
            </li>
          </ul>

          <div className="btn-out"><a href="#" className="btn btn-blue" onClick={handleUpdate}>Update</a></div>
        </form>
      </div>
    </div>
  )
}
