import React from "react";
import { FormControl, MenuItem, Select, Pagination, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  paginationContainer: {
    width: '100%',
    float: 'left',
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
  },
  paginationBox: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    float: 'none',
    gap: '10px',
    justifyContent: 'center',
    verticalAlign: 'top',
  },
  paginationRoot: {
    '& ul li': {
      paddingBottom: '0px !important',
    }
  }
})

export default function CustomPagination({ count, handleChange, page, perPage, handlePerPageChange, siblingCount }) {
  const classes = useStyles();
  const matchesMobile = useMediaQuery("(max-width:500px)");

  return (
    <div className={classes.paginationContainer}>
      <FormControl sx={{ m: 1, minWidth: 65, mr: 2 }} size="small">
        <Select
          value={perPage}
          onChange={handlePerPageChange}
        >
          <MenuItem value='10'>10</MenuItem>
          <MenuItem value='20'>20</MenuItem>
          <MenuItem value='30'>30</MenuItem>
          <MenuItem value='40'>40</MenuItem>
          <MenuItem value='50'>50</MenuItem>
        </Select>
      </FormControl>
      
      <div className={classes.paginationBox}>
        <Pagination classes={{ root: classes.paginationRoot }} count={count} siblingCount={siblingCount ?? (matchesMobile ? 0 : 1)} onChange={handleChange} page={page} />
      </div>
    </div>
  )
}
