import React, { useEffect, useState } from "react";
import StorePagination from "./store-pagination";
import { withPrefix } from "gatsby";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  setUpDomain: {
    color: '#5757ff',
    '&:hover': {
      textDecoration: 'underline',
    }
  }
})

export default function StorefrontList({stores, selectedStore, setSelectedStore }) {
  const classes = useStyles();

  //Pagination code: start
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(3);
  const [count, setCount] = useState(1);

  useEffect(() => {
    setCount(Math.ceil(stores.length / perPage));
  }, [stores, perPage])

  const handlePaginationChange = async (event, newPage) => {
    event.preventDefault();
    setPage(newPage);
  };

  const handlePerPageChange = (e) => {
    const perPageVal = +e.target.value;
    setPerPage(perPageVal);
    setPage(1);
  }
  //Pagination code: end

  return (
    <div className="box">
      <h2>My Storefronts</h2>
      <ul className="store-list">
        {!stores.length && (
          [1, 2, 3].map((i) => (
            <li key={i}>
            <a href="#" style={{ background: '#fff' }}>
              <div className="icon"></div>
              <div className="aside">
                <div style={{ height: 16, width: '98%', background: '#F5F4FD' }}></div>
                <div style={{ height: 16, width: '50%', background: '#F5F4FD', marginTop: '8px' }}></div>
              </div>
            </a>
          </li>
          ))
        )}
        
        {stores.slice((page-1)*perPage, page*perPage).map((store, index) => (
          <li key={index}>
            <a href="#" onClick={() => setSelectedStore(store)} style={{ background: store.id === selectedStore.id ? 'rgb(13 110 253 / 11%)': '#fff' }}>
              <div className="icon"><img src={withPrefix("assets/img/icon-store.svg")} alt="icon-store" /></div>
              <div className="aside">
                <h4>{store.store_title}</h4>
                {store.shopify_store_url ? (
                  <p>{store.shopify_store_url.replace(/^https?:\/\//, '')}</p>
                ) : (
                  <p className={classes.setUpDomain}>Setup Domain Name</p>
                )}
              </div>
            </a>
          </li>
        ))}
      </ul>

      <StorePagination count={count} handleChange={handlePaginationChange} page={page} perPage={perPage} handlePerPageChange={handlePerPageChange} siblingCount={0} />
    </div>
  );
}
