import React, { useState, useEffect } from "react";
import ProductList from "./product-list";
import { withPrefix } from "gatsby";
import CustomPagination from "./pagination";
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import { getStoreCollectionsWithProducts, bulkDeleteStoreCollections, bulkEditCollectionProductTitles, bulkEditCollectionProductPrices, updateStoreCollection, updateStoreCollectionImage, getResetAllFlags, selectStoresState } from "../../sagas/stores/storesSlice";
import Dialog from '@mui/material/Dialog';
import { isLoggedIn } from "../../services/auth";
import { navigate } from "gatsby";
import { ReplaceImageModal } from "./replace-image-modal";
import CropImageModal from "../CropImageModal";
import { BulkEditModal as BulkPriceEditModal } from "./product-detail";

export default function CollectionsContent({ store, tabClicked }) {
  const dispatch = useDispatch();
  const { 
    isFetchingStoreCollectionsWithProducts,
    isFetchingStoreCollectionsWithProductsSuccess, 
    isFetchingStoreCollectionsWithProductsFailed, 
    StoreCollectionsWithProductsResponse, 
    isBulkDeletingStoreCollections,
    isBulkDeletingStoreCollectionsSuccess, 
    isBulkDeletingStoreCollectionsFailed, 
    bulkDeleteStoreCollectionsResponse,
    isBulkEditingCollectionProductTitles,
    isBulkEditingCollectionProductTitlesSuccess,
    isBulkEditingCollectionProductTitlesFailed,
    bulkEditCollectionProductTitlesResponse,
    isBulkEditingCollectionProductPrices,
    isBulkEditingCollectionProductPricesSuccess,
    isBulkEditingCollectionProductPricesFailed,
    bulkEditCollectionProductPricesResponse,
    isUpdatingStoreCollectionSuccess,
    UpdateStoreCollectionResponse,
    isUpdatingStoreCollectionImageSuccess,
    UpdateStoreCollectionImageResponse,
  } = useSelector(selectStoresState);

  const [pageState, setPageState] = useState('collection-list');
  const [selectedCollection, setSelectedCollection] = useState();

  useEffect(() => {
    setPageState('collection-list');
  }, [tabClicked])

  useEffect(() => {
    setPageState('collection-list');
  }, [store])

  const [collections, setCollections] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isCollectionsReloadRequired, setIsCollectionsReloadRequired] = useState(true);

  useEffect(() => {
    if (store?.id) setIsCollectionsReloadRequired(true);
  }, [store])

  useEffect(() => {
    if (store?.id && isCollectionsReloadRequired) {
      setCollections([]);
      setTotalCount(0);
      dispatch({
        type: getStoreCollectionsWithProducts.type,
        payload: {
          storeId: store.id,
        }
      });
    }
  }, [store, isCollectionsReloadRequired])

  useEffect(() => {
    if (isFetchingStoreCollectionsWithProducts || isBulkDeletingStoreCollections || isBulkEditingCollectionProductTitles || isBulkEditingCollectionProductPrices) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [isFetchingStoreCollectionsWithProducts, isBulkDeletingStoreCollections, isBulkEditingCollectionProductTitles, isBulkEditingCollectionProductPrices])

  useEffect(() => {
    if (isFetchingStoreCollectionsWithProductsSuccess) {
      setCollections(StoreCollectionsWithProductsResponse.collections ?? []);
      setTotalCount(StoreCollectionsWithProductsResponse.count);
      setIsCollectionsReloadRequired(false);
    } else if (isFetchingStoreCollectionsWithProductsFailed) {
      setIsCollectionsReloadRequired(false);
      //swal show here
      Swal.fire({
        icon: 'error',
        text: 'Unable to fetch collections, please give it another go.',
        confirmButtonText: 'Retry',
      }).then(async(result) => {
        if (result.isConfirmed) {
          dispatch({
            type: getStoreCollectionsWithProducts.type,
            payload: {
              storeId: store.id,
            }
          });
        }
      })
    }
  }, [isFetchingStoreCollectionsWithProductsSuccess, isFetchingStoreCollectionsWithProductsFailed, StoreCollectionsWithProductsResponse])

  const [refinedCollections, setRefinedCollections] = useState([]);

  useEffect(() => {
    setRefinedCollections(collections);
    setSearchValue('');
  }, [collections])
  
  //Pagination code: start
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [count, setCount] = useState(1);

  useEffect(() => {
    setPage(1);
    setPerPage(10);
    setCount(1);
  }, [store])

  useEffect(() => {
    setCount(Math.ceil(refinedCollections.length / perPage));
    setAllChecked(false);
  }, [refinedCollections, perPage])

  const handlePaginationChange = async (event, newPage) => {
    event.preventDefault();
    setPage(newPage);
  };

  const handlePerPageChange = (e) => {
    const perPageVal = +e.target.value;
    setPerPage(perPageVal);
    setPage(1);
  }
  //Pagination code: end

  const getProductsLengthText = (count) => {
    if (!count) return '';
    if (count === 1) return '1 Product';
    if (count > 1) return `${count} Products`;
  }

  const handleSelectCollection = (collection, e) => {
    e.preventDefault();
    if (collection.node.productsCount === 0) return;

    setSelectedCollection(collection);
    setPageState('product-list');
  }

  const [searchValue, setSearchValue] = useState('');
  useEffect(() => {
    const refinedSearchValue = searchValue.toLowerCase();
    setRefinedCollections(collections.filter((c) => c.node.title.toLowerCase().indexOf(refinedSearchValue) === 0));
  }, [searchValue])

  const [selectedCollections, setSelectedCollections] = useState([]);
  const [allChecked, setAllChecked] = useState(false);
  const handleCheckAll = (e) => {
    if (e.target.checked) {
      setSelectedCollections(refinedCollections?.slice((page-1)*perPage, page*perPage));
      setAllChecked(true);
    } else {
      setSelectedCollections([]);
      setAllChecked(false);
    }
  }

  const handleCheckCollection = (collection, e) => {
    if (e.target.checked) {
      setSelectedCollections([...selectedCollections, collection]);
    } else {
      setSelectedCollections((collections) => collections.filter((c) => c.node.id !== collection.node.id));
    }
    setAllChecked(false);
  }

  const removeSelectedCollections = () => {
    if (selectedCollections.length > 0) {
      Swal.fire({
        title: 'Are you sure?',
        html: '<div>Deleting this collection will permanently remove it and all items inside of it. <br/>This action cannot be undone.</div>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Confirm'
      }).then(async(result) => {
        if (result.isConfirmed) {
          const collections = selectedCollections.map((c) => {
            const tagId = c?.node?.ruleSet?.rules?.find((rule) => rule.relation === 'EQUALS')?.condition;
            return {
              id: c.node.id,
              tagId,
              productsCount: c.node.productsCount,
            }
          });
          const data = {
            storeId: store.id,
            collections,
          }

          dispatch({
            type: bulkDeleteStoreCollections.type,
            payload: data,
          });
        }
      })
    } else {
      Swal.fire({
        icon: 'warning',
        text: 'Please select collections to remove!',
      })
    }
  }

  useEffect(() => {
    if (isBulkDeletingStoreCollectionsSuccess) {
      setSelectedCollections([]);
      setAllChecked(false);
      setIsCollectionsReloadRequired(true);
      
      if (bulkDeleteStoreCollectionsResponse.status === 'partially done') {
        Swal.fire({
          icon: 'info',
          text: `Oops! It seems that some products didn't get fully removed. Please give it another go and try removing them again. We apologize for any inconvenience caused and appreciate your patience.`,
        })
      } else {
        Swal.fire({
          icon: 'success',
          text: 'Successfully removed collections',
          timer: 1500,
        })
      }
    } else if (isBulkDeletingStoreCollectionsFailed) {
      Swal.fire({
        icon: 'error',
        text: 'Something went wrong, please try again later.',
      })
    }
  }, [isBulkDeletingStoreCollectionsSuccess, isBulkDeletingStoreCollectionsFailed, bulkDeleteStoreCollectionsResponse])

  // Bulk title edit: start
  const [openBulkTitleEditModal, setOpenBulkTitleEditModal] = useState(false);
  const bulkTitleEdit = () => {
    if (selectedCollections.length > 0) {
      setOpenBulkTitleEditModal(true);
    } else {
      Swal.fire({
        icon: 'warning',
        text: 'Please select collections to bulk edit product titles!',
      })
    }
  }
  const handleCloseBulkTitleEdit = () => {
    setOpenBulkTitleEditModal(false);
  }
  const [titlePrefix, setTitlePrefix] = useState();
  const handleConfirmBulkTitleEdit = (value) => {
    setOpenBulkTitleEditModal(false);
    if (!value) return;

    setTitlePrefix(value);

    Swal.fire({
      title: 'Are you sure?',
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: 'Confirm'
    }).then(async(result) => {
      if (result.isConfirmed) {
        setIsLoading(true);

        const collections = selectedCollections.map((c) => {
          const tagId = c?.node?.ruleSet?.rules?.find((rule) => rule.relation === 'EQUALS')?.condition;
          return {
            id: c.node.id,
            tagId,
            productsCount: c.node.productsCount,
          }
        });
        const data = {
          storeId: store.id,
          collections,
          titlePrefix: value,
        }

        dispatch({
          type: bulkEditCollectionProductTitles.type,
          payload: data,
        });
      }
    })
  }

  useEffect(() => {
    if (isBulkEditingCollectionProductTitlesSuccess) {
      if (bulkEditCollectionProductTitlesResponse.status === 'partially done') {
        Swal.fire({
          icon: 'info',
          text: `Oops! It seems that some products didn't get fully updated. Please give it another go and try updating them again. We apologize for any inconvenience caused and appreciate your patience.`,
        });
        setIsLoading(false);
      } else {
        Swal.fire({
          icon: 'success',
          text: 'Successfully updated product titles',
          timer: 1500,
        });

        //If collection name is different from the titlePrefix then update it.
        for (const collection of selectedCollections) {
          dispatch({
            type: updateStoreCollection.type,
            payload: {
              storeId: store.id,
              collectionId: collection.node.id.replace('gid://shopify/Collection/', ''),
              data: { title: titlePrefix },
            }
          });
        }
      }

      setSelectedCollections([]);
      setAllChecked(false);
    } else if (isBulkEditingCollectionProductTitlesFailed) {
      Swal.fire({
        icon: 'error',
        text: 'Something went wrong, please try again later.',
      });
      setIsLoading(false);
    }
  }, [isBulkEditingCollectionProductTitlesSuccess, isBulkEditingCollectionProductTitlesFailed, bulkEditCollectionProductTitlesResponse, titlePrefix])
  
  useEffect(() => {
    if (isUpdatingStoreCollectionSuccess) {
      setIsCollectionsReloadRequired(true);
      dispatch(getResetAllFlags());
    }
  }, [isUpdatingStoreCollectionSuccess, UpdateStoreCollectionResponse])
  // Bulk title edit: end

  // Bulk price edit: start
  const [openBulkPriceEditModal, setOpenBulkPriceEditModal] = useState(false);
  const bulkPriceEdit = () => {
    if (selectedCollections.length > 0) {
      setOpenBulkPriceEditModal(true);
    } else {
      Swal.fire({
        icon: 'warning',
        text: 'Please select collections to bulk edit product prices!',
      })
    }
  }
  const handleCloseBulkPriceEdit = () => {
    setOpenBulkPriceEditModal(false);
  }
  const handleConfirmBulkPriceEdit = (value, mode) => {
    setOpenBulkPriceEditModal(false);
    if (!value) return;

    Swal.fire({
      title: 'Are you sure?',
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: 'Confirm'
    }).then(async(result) => {
      if (result.isConfirmed) {
        setIsLoading(true);

        const collections = selectedCollections.map((c) => {
          const tagId = c?.node?.ruleSet?.rules?.find((rule) => rule.relation === 'EQUALS')?.condition;
          return {
            id: c.node.id,
            tagId,
            productsCount: c.node.productsCount,
          }
        });
        const data = {
          storeId: store.id,
          collections,
          bulkEditOption: { mode, value },
        }

        dispatch({
          type: bulkEditCollectionProductPrices.type,
          payload: data,
        });
      }
    })
  }

  useEffect(() => {
    if (isBulkEditingCollectionProductPricesSuccess) {
      if (bulkEditCollectionProductPricesResponse.status === 'partially done') {
        Swal.fire({
          icon: 'info',
          text: `Oops! It seems that some products didn't get fully updated. Please give it another go and try updating them again. We apologize for any inconvenience caused and appreciate your patience.`,
        });
      } else {
        Swal.fire({
          icon: 'success',
          text: 'Successfully updated product prices',
          timer: 1500,
        });
      }

      setSelectedCollections([]);
      setAllChecked(false);
      setIsLoading(false);
    } else if (isBulkEditingCollectionProductPricesFailed) {
      Swal.fire({
        icon: 'error',
        text: 'Something went wrong, please try again later.',
      });
      setIsLoading(false);
    }
  }, [isBulkEditingCollectionProductPricesSuccess, isBulkEditingCollectionProductPricesFailed, bulkEditCollectionProductPricesResponse])
  
  // Bulk price edit: end

  //This is new concept
  //If there are syncing collections, we need to refresh the collectiosn automatically
  useEffect(() => {
    for (let index = 0; index < collections.length; index++) {
      const collection = collections[index];

      if (collection.syncing) {
        const timeout = setTimeout(() => {
          setIsCollectionsReloadRequired(true);
        }, 60000)//1min
      
        return () => {
          clearTimeout(timeout);
        }
      }
    }
  }, [collections])


  //Collection Image stuff: start
  const [showReplaceImageModal, setShowReplaceImageModal] = useState(false);
  const handleCloseReplaceModal = () => {
    setShowReplaceImageModal(false);
  }

  const handleUploadNewImage = (imgUri) => {
    if (!imgUri) return;

    setShowReplaceImageModal(false);
    openCropTool(imgUri);
  }

  const [selectedCollectionForImage, setSelectedCollectionForImage] = useState();
  const handleClickImage = (e, collection) => {
    e.stopPropagation();

    setSelectedCollectionForImage(collection);

    if (collection.node.image?.url) {
      Swal.fire({
        // title: 'Update Product Image',
        html: `<img src="${collection.node.image?.url}" alt="collection-img" style="background: #fff" />`,
        confirmButtonText: 'Edit',
        confirmButtonColor: '#5757FF',
        showDenyButton: true,
        denyButtonText: `Replace`,
        denyButtonColor: '#5757FF',
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {//Edit feature
          openCropTool(collection.node.image?.url);
        } else if (result.isDenied) {//Replace feature
          setShowReplaceImageModal(true);
        }
      })
    } else {
      setShowReplaceImageModal(true);
    }
  }

  const [cropModalOpen, setCropModalOpen] = React.useState(false);
  const [cropper, setCropper] = React.useState(<any />);
  const [picture, setPicture] = React.useState("");
  const [minCropBoxWidth, setMinCropBoxWidth] = React.useState(0);
  const [minCropBoxHeight, setMinCropBoxHeight] = React.useState(0);

  const getCropData = async () => {
    if (typeof cropper !== "undefined") {
      const base64data = cropper.getCroppedCanvas().toDataURL();

      const img = new Image();
      img.src = base64data;
      await new Promise(resolve => img.onload = resolve);

      setCropModalOpen(false);
  
      if (img.width >= 1024 && img.width < 4472 && img.height >= 1024 && img.height < 4472) {
        const refinedData = base64data.slice(22);
        updateCollectionImage(refinedData);
      } else {
        Swal.fire({
          icon: 'info',
          text: `The minimum shopify collection image size is 1024 x 1024 pixels. Collection images also can't be bigger than 4472 x 4471 pixels and over 20MB in file size.`,
        }).then(() => {
          setCropModalOpen(true);
        });
      }
    }
  };
  const openCropTool = async (imgUri) => {
    const img = new Image();
    img.src = imgUri;
    await new Promise(resolve => img.onload = resolve);

    if (img.width >= 1024 && img.height >= 1024) {
      setMinCropBoxWidth(Math.ceil(350/img.width*1024));
      setMinCropBoxHeight(Math.ceil(350/img.width*1024));
      setPicture(imgUri);
      setCropModalOpen(true);
    } else {
      Swal.fire({
        icon: 'info',
        text: `The minimum shopify collection image size is 1024 x 1024 pixels. Collection images also can't be bigger than 4472 x 4471 pixels and over 20MB in file size.`,
      }).then(() => {
        setShowReplaceImageModal(true);
      });
    }
  }

  const updateCollectionImage = (base64) => {
    dispatch({
      type: updateStoreCollectionImage.type,
      payload: {
        storeId: store.id,
        collectionId: selectedCollectionForImage.node.id.replace('gid://shopify/Collection/', ''),
        attachment: base64,
      }
    });
    setIsLoading(true);
  }

  React.useEffect(() => {
    if (isUpdatingStoreCollectionImageSuccess && UpdateStoreCollectionImageResponse) {
      setIsCollectionsReloadRequired(true);
    }
  }, [isUpdatingStoreCollectionImageSuccess, UpdateStoreCollectionImageResponse])
  //Collection Image stuff: end

  return (
    store ? (
      pageState === 'collection-list' ? 
        <div className="store-collection">
          <div className="form-field">
            <h2>
              Manage Collections
              <img
                style={{ width: 20, height: 20, cursor: "pointer", marginLeft: '16px' }}
                onClick={() => setIsCollectionsReloadRequired(true)}
                src={withPrefix("assets/img/refresh-svgrepo-com.svg")}
                alt="img/art-2.png"
              />
            </h2>
            <input type="text" name="domain" id="domain" placeholder="Search..." value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
          </div>

          <div className="table-cont">
            {isLoading && (
              <img src={withPrefix("assets/img/green-little-balls.gif")} alt="loading" style={{ objectFit: 'contain', width: '100%', height: '400px' }} />
            )}
            
            {!isLoading && refinedCollections.length > 0 && (
              <div className="table-row">
                <div className="box" style={{ backgroundColor: '#ecebff', width: '100%' }}>
                  <div className="table-col">
                    <div className="check-options">
                      <input name="checkbox0" id="checkbox0" type="checkbox" className="styled" checked={allChecked} onChange={handleCheckAll} />
                      <label htmlFor="checkbox0"><span className="sr-only">CheckBox</span></label>
                    </div>
                  </div>
                  <div className="btns-out">
                    <a href="#" className="btn" onClick={removeSelectedCollections}>Delete Collection</a>
                    <a href="#" className="btn" onClick={bulkTitleEdit}>Bulk Title Edit</a> 
                    <a href="#" className="btn" onClick={bulkPriceEdit}>Bulk Price Edit</a> 
                  </div>
                </div>
              </div>
            )}

            {!isLoading && refinedCollections.slice((page-1)*perPage, page*perPage).map((collection, index) => (
              <div className="table-row" key={index}>
                <a href="#" className="box" style={{ cursor: collection.node.productsCount === 0 ? 'not-allowed': 'pointer' }} onClick={(e) => handleSelectCollection(collection, e)}>
                  <div className="table-col">
                    <div className="check-options" onClick={(e) => e.stopPropagation()}>
                      <input name={`checkbox${index+1}`} id={`checkbox${index+1}`} type="checkbox" checked={selectedCollections.some((sc) => sc.node.id === collection.node.id)} onChange={(e) => handleCheckCollection(collection, e)} className="styled" />
                      <label htmlFor={`checkbox${index+1}`}><span className="sr-only">CheckBox</span></label>
                    </div>
                  </div>
                  <div className="table-col" style={{ textAlign: 'left' }}>
                    <div className="collection-img">
                      <img 
                        style={{ width: 42 }}
                        src={collection.node.image?.url ?? withPrefix("assets/img/icon-collection.svg")} 
                        alt="placeholder" 
                        onClick={(e) => handleClickImage(e, collection)} 
                      />
                    </div>
                  </div>
                  <div className="table-col" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div>
                      <div className="name">{collection.node.title}</div>
                      <div className="count">
                        {collection.syncing ? 'SYNCING...' : getProductsLengthText(collection.node.productsCount)}
                      </div>                    
                    </div>
                    {collection.syncing && (
                      <img
                        style={{ width: 31, height: 31 }}
                        src={withPrefix("assets/img/icon-syncing.gif")}
                        alt="syncing"
                      />                    
                    )}
                  </div>
                  <div className="table-col">
                    <div className="items-wrap">
                      {collection.node.products.nodes.filter((product) => !!product.featuredImage?.url).map((product, pindex) => (
                        <div className="item" key={`${index}-${pindex}`}>
                          <span className="inn">
                            <img src={product.featuredImage.url} alt={product.title} />
                          </span>
                        </div>
                      ))}
                      {collection.node.productsCount === 0 && (
                        <div className="item" style={{ width: '100%' }}>
                          <span className="inn" style={{ color: '#5757ff', background: 'transparent', fontSize: '16px', fontFamily: '"Albert Sans", sans-serif' }}>
                            This collection is empty.
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </a>
              </div>          
            ))}
            {!isLoading && !totalCount && (
              <p style={{ fontSize: '16px' }}>No collections found</p>
            )}
          </div>

          {!!totalCount && <CustomPagination count={count} handleChange={handlePaginationChange} page={page} perPage={perPage} handlePerPageChange={handlePerPageChange} />}
        
          <BulkTitleEditModal
            open={openBulkTitleEditModal}
            handleClose={handleCloseBulkTitleEdit}
            handleConfirm={handleConfirmBulkTitleEdit}
          />

          <BulkPriceEditModal
            open={openBulkPriceEditModal}
            handleClose={handleCloseBulkPriceEdit}
            handleConfirm={handleConfirmBulkPriceEdit}
          />

          <ReplaceImageModal
            open={showReplaceImageModal}
            handleClose={handleCloseReplaceModal}
            handleConfirm={handleUploadNewImage}
          />

          <CropImageModal 
            cropModalOpen={cropModalOpen} 
            setCropModalOpen={setCropModalOpen} 
            picture={picture}
            cropper={cropper} 
            setCropper={setCropper}
            getCropData={getCropData}
            minCropBoxWidth={minCropBoxWidth}
            minCropBoxHeight={minCropBoxHeight}
          />
        </div>
      : pageState === 'product-list' ? 
        <ProductList store={store} collection={selectedCollection} setPageState={setPageState} setIsCollectionsReloadRequired={setIsCollectionsReloadRequired} />
      : <></>
    ) : (
      <div className="store-collection">
        <div className="form-field" style={{ marginBottom: '15px' }}>
          <h2>
            Manage Collections
          </h2>
        </div>

        <div className="table-cont">
          <div className="table-row">
            <div className="box" style={{ backgroundColor: '#ecebff' }}>
              <div className="table-col">
                <div className="check-options">
                  <input name="checkbox0" id="checkbox0" type="checkbox" className="styled" />
                  <label htmlFor="checkbox0"><span className="sr-only">CheckBox</span></label>
                </div>
              </div>
            </div>
          </div>

          {[1,2,3].map((index) => (
            <div className="table-row" key={index}>
              <a href="#" className="box">
                <div className="table-col">
                  <div className="check-options" onClick={(e) => e.stopPropagation()}>
                    <input name={`checkbox${index+1}`} id={`checkbox${index+1}`} type="checkbox" className="styled" />
                    <label htmlFor={`checkbox${index+1}`}><span className="sr-only">CheckBox</span></label>
                  </div>
                </div>
                <div className="table-col" style={{ textAlign: 'left' }}>
                  <img src={withPrefix("assets/img/placeholder.png")} alt="placeholder" />
                </div>
                <div className="table-col" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div>
                    <div className="name" style={{ height: 16, width: '98px', background: '#ffffff' }}></div>
                    <div className="count" style={{ height: 16, width: '50px', background: '#ffffff' }}></div>
                  </div>
                </div>
                <div className="table-col">
                  <div className="items-wrap">
                    {[1,2,3,4,5,6,7].map((pindex) => (
                      <div className="item" key={`${index}-${pindex}`}>
                        <span className="inn" style={{ paddingTop: '100%' }}></span>
                      </div>
                    ))}
                  </div>
                </div>
              </a>
            </div>          
          ))}
        </div>
      </div>
    )
  )
}

export const BulkTitleEditModal = ({ open, handleClose, handleConfirm }) => {
  const [value, setValue] = useState('');

  const handleValue = (e) => {
    setValue(e.target.value);
  }

  const handleCloseModal = () => {
    setValue('');
    handleClose();
  }

  const handleConfirmModal = () => {
    if (!isLoggedIn()) {
      navigate("/signin");
    } else {
      handleConfirm(value);
      setValue('');
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleCloseModal}
      aria-labelledby="bulk-edit-modal-title"
      aria-describedby="bulk-edit-modal-description"
      PaperProps={{ style: { pointerEvents: 'auto', borderRadius: '15px', width: 623 } }}
    >
      <div className="bulk-edit-modal">
        <div className="box">
          <div className="box-inn">
            <div className="close" data-bs-dismiss="modal" onClick={handleClose}>
              <img src={withPrefix("assets/img/icon-close-black.svg")} alt="icon-close-white" />
            </div>

            <p className="head">Bulk Edit Product Titles</p>
            <p className="description">Enter value to apply to products</p>

            <div className="input-box">
              <input id="bulkEditValue" value={value} onChange={handleValue} name="bulkEditValue" type="text" placeholder="Enter value" />
            </div>

            <p className="description" style={{ marginTop: '16px' }}>Preview: {value ? `${value}:`: ''} Women's High Top Canvas Shoes</p>
          </div>
        </div>  

        <div className="modal-actions">
          <button type="button" onClick={handleConfirmModal} className="confirm">UPDATE ALL</button>   
        </div>
      </div>
    </Dialog>        
  )
}
