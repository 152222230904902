import React, { useState } from "react";
import { withPrefix } from "gatsby";
import Dialog from '@mui/material/Dialog';
import { useDropzone } from 'react-dropzone';

const styles = {
  dropzone: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    borderRadius: '8px',
    color: '#10100a',
    width: '100%',
    height: '359px',
  },
  dropzoneText: {
    color: '#000000',
    fontFamily: 'Albert Sans',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '16px',
    lineHeight: '20px',
    paddingBottom: 5,
    marginBottom: 0,
  },
  thumbsContainer: {
    padding: '0',
    display: 'flex',
    justifyContent: 'center',
  },
  thumb: {
    width: '100%',
    maxWidth: '366px',
    float: 'left',
    borderRadius: '15px',
    boxShadow: 'rgba(87, 87, 225, 0.35) 0 5px 10px',
    position: 'relative',
    margin: '8px 0 0 0',
  },
  thumbInner: {
    width: '100%',
    float: 'left',
    textSlign: 'center',
    borderRadius: '15px',
    overflow: 'hidden',
  },
  img: {
    width: '100%',
    maxWidth: '366px',
    borderRadius: '15px',
    height: '366px',
    objectFit: 'cover',
  },
  iconCloseBox: {
    width: '42px',
    height: '42px',
    float: 'right',
    position: 'absolute',
    top: '-16px',
    right: '-16px',
  },
  iconClose: {
    width: '42px',
    height: '42px',
    float: 'left',
    fontWeight: 'inherit',
    fontSize: '24px',
    color: '#ffffff',
    lineHeight: '42px',
    textDecoration: 'none',
    textAlign: 'center',
    background: '#FF3333',
    borderRadius: '360px',
  },
  browseBtn: {
    border: '1px solid #10100A',
    borderRadius: '8px',
    padding: '16px 24px',
    color: '#595A5C',
    background: 'transparent',
    textTransform: 'uppercase',
    fontFamily: 'Roboto Mono',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '16px',
    lineHeight: '20px',
    marginTop: 16,
  },
}

export const ReplaceImageModal = ({ open, handleClose, handleConfirm }) => {
  const [previewImage, setPreviewImage] = React.useState("");

  React.useEffect(() => {
    setPreviewImage("");
  }, [open])

  const handleImage = async (files) => {
    if (!files || files.length == 0) {
      return;
    }
    const file = files[0];

    if (!file) return;

    if (file.type === 'image/heif' || file.type === 'image/heic') {
      // const convertedData = await convertHeicToPng(file);
      // setPicture(convertedData);
      // setPreviewImage(convertedData);
    } else {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      await new Promise(resolve => reader.onload = resolve);
      const base64data = reader.result;
      setPreviewImage(base64data);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      // 'image/*': ['.jpeg', '.png', '.jpg', '.webp', '.heic', '.heif']
      'image/*': ['.jpeg', '.png', '.jpg', '.webp']
    },
    onDrop: acceptedFiles => {
      handleImage(acceptedFiles);
    },
    maxFiles: 1,
  });

  const [isDragActive, setIsDragActive] = useState(false);

  const handleDragEnter = () => {
    setIsDragActive(true);
  };

  const handleDragLeave = () => {
    setIsDragActive(false);
  };

  const handleCloseModal = () => {
    setPreviewImage('');
    handleClose();
  }

  const handleConfirmModal = () => {
    if (!previewImage) return;
    handleConfirm(previewImage);
  }

  return (
    <Dialog
      open={open}
      onClose={handleCloseModal}
      aria-labelledby="replace-image-dialog-title"
      aria-describedby="replace-image-dialog-description"
      PaperProps={{ style: { pointerEvents: 'auto', borderRadius: '15px', width: 512 } }}
    >
      <div className="replace-product-image">
        <div className="box">
          <div className="box-inn" style={{ height: 420 }}>
            <div style={{ padding: '50px 40px 0px', paddingTop: previewImage ? '36px' : '46px' }}>
              {!previewImage && (
                <div 
                  {...getRootProps({ })} 
                  style={{ ...styles.dropzone, border: `1px dashed ${isDragActive ? '#A020F0' : '#000000'}` }}
                  onDragEnter={handleDragEnter}
                  onDragLeave={handleDragLeave}
                >
                  <input {...getInputProps()} />
                  <img src={withPrefix("assets/img/upload-img.svg")} alt="upload-icon" />
                  <p style={{ ...styles.dropzoneText, marginTop: 24 }}>Drag & Drop to Upload File</p>
                  <p style={styles.dropzoneText}>or</p>
                  <button style={styles.browseBtn}>Browse File</button>
                </div>
              )}
          
              {previewImage && (
                <div style={styles.thumbsContainer}>
                  <div style={styles.thumb}>
                    <div style={styles.thumbInner}>
                      <img src={previewImage} style={styles.img} alt="preview" />
                    </div>
                    <div style={styles.iconCloseBox}>
                      <a onClick={() => setPreviewImage('')} className="far fa-times" style={styles.iconClose}></a>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>  

        <div className="modal-actions">
          {previewImage && <button onClick={handleConfirmModal} className="confirm">UPLOAD</button>}
          <button onClick={handleCloseModal} className="cancel">CANCEL</button>            
        </div>
      </div>
    </Dialog>        
  )
}
