import React, { useState, useEffect } from "react";
import OwlCarousel from "react-owl-carousel2";
import Swal from 'sweetalert2';
import { withPrefix } from "gatsby";
import Tooltip from '@mui/material/Tooltip';
import CropImageModal from "../CropImageModal";
import Dialog from '@mui/material/Dialog';
import { useDispatch, useSelector } from 'react-redux';
import { getStoreProductDetails, updateStoreProductDetails, updateStoreProductImage, undoStoreProductImage, bulkDeleteStoreProducts, selectStoresState } from "../../sagas/stores/storesSlice";
import { isLoggedIn } from "../../services/auth";
import { navigate } from "gatsby";
import { ReplaceImageModal } from "./replace-image-modal";

const options = {
  loop: false,
  margin: 32,
  nav: true,
  dotsEach: true,
  dots: false,
  autoplay: false,
  // navText: [
  //   '<i class="fa fa-angle-left" aria-hidden="true"></i>',
  //   '<i class="fa fa-angle-right" aria-hidden="true"></i>',
  // ],
  navElement: 'button',
  responsive: {
    0: {
      items: 1
    },
    600: {
      items: 2
    },
    1200: {
      items: 3
    }
  },
};

const sizeOrders = { '2XS': 0, 'XS': 1, 'S': 2, "M": 3, "L": 4, "XL": 5, '2XL': 6, '3XL': 7, '4XL': 8, '5XL': 9, '6XL': 10, 'a': 11 };

export default function ProductDetail({ store, collection, product, setPageState, setShowProductDetail, setIsProductsReloadRequired }) {
  const dispatch = useDispatch();
  const { 
    isBulkDeletingStoreProducts,
    isBulkDeletingStoreProductsSuccess,
    isBulkDeletingStoreProductsFailed,
    isFetchingStoreProductDetails,
    isFetchingStoreProductDetailsSuccess,
    isFetchingStoreProductDetailsFailed,
    StoreProductDetails,
    isUpdatingStoreProductDetails,
    isUpdatingStoreProductDetailsSuccess,
    isUpdatingStoreProductDetailsFailed,
    isUpdatingStoreProductImage,
    isUpdatingStoreProductImageSuccess,
    isUpdatingStoreProductImageFailed,
    UpdateStoreProductImageResponse,
    isUndoingStoreProductImage,
    isUndoingStoreProductImageSuccess,
    isUndoingStoreProductImageFailed,
    UndoStoreProductImageResponse,
  } = useSelector(selectStoresState);
  
  const [status, setStatus] = useState('active');
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [selectedVariants, setSelectedVariants] = useState([]);
  const [variants, setVariants] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [productDetail, setProductDetail] = useState();

  const fetchProductDetails = async() => {
    setProductDetail();
    const storeId = store.id;
    const productId = product.id.replace('gid://shopify/Product/', '');

    dispatch({
      type: getStoreProductDetails.type,
      payload: {
        storeId,
        productId,
      }
    });
  }

  useEffect(() => {
    if (isFetchingStoreProductDetailsSuccess) {
      handleProductResponse(StoreProductDetails);
    } else if (isFetchingStoreProductDetailsFailed) {
      Swal.fire({
        icon: 'error',
        text: 'Unable to fetch product details',
        confirmButtonText: 'Retry',
      }).then(async(result) => {
        if (result.isConfirmed) fetchProductDetails();
      })
    }
  }, [isFetchingStoreProductDetailsSuccess, isFetchingStoreProductDetailsFailed, StoreProductDetails])

  useEffect(() => {
    if (isBulkDeletingStoreProducts || isFetchingStoreProductDetails || isUpdatingStoreProductDetails || isUpdatingStoreProductImage || isUndoingStoreProductImage) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [isBulkDeletingStoreProducts, isFetchingStoreProductDetails, isUpdatingStoreProductDetails, isUpdatingStoreProductImage, isUndoingStoreProductImage])

  useEffect(() => {
    if (store.id && product.id && store.id === product.storeId) {
      fetchProductDetails();
    }
  }, [store, product])

  const handleProductResponse = (response) => {
    if (response.code === 200) {
      const productDetail = response.data;
      setProductDetail(productDetail);
      setStatus(productDetail.status.toLowerCase());
      setTitle(productDetail.title);
      setDescription(productDetail.body_html);
      let variantsReference = [...response.variantsReference];
      const variants = variantsReference.sort((a, b) => sizeOrders[a.size] - sizeOrders[b.size]).map((variant) => {
        const shopifyVariant = productDetail.variants.find((sv) => sv.title === variant.title);
        const price = shopifyVariant?.price ?? variant.cost;
        const profit = (parseFloat(price) - parseFloat(variant.cost));
        const markup = (profit / parseFloat(variant.cost) * 100);
        return {
          ...variant,
          price,
          profit: profit.toFixed(2),
          markup: markup.toFixed(0),
        }
      });
      setVariants(variants);
      const ids = productDetail.variants.map((v) => {
        const reference = response.variantsReference.find((vr) => vr.title === v.title);
        return reference.id;
      });

      setSelectedVariants(ids);
    }
  }

  const handleChangeStatus = (e) => {
    if (e.target.checked) {
      setStatus('active');
      updateProductStatus('active');
    } else {
      setStatus('draft');
      updateProductStatus('draft');
    }
  }

  const handleVariantChange = (e, clicked_id) => {
    e.preventDefault();

    if (selectedVariants.includes(clicked_id)) {
      setSelectedVariants((ids) => ids.filter((id) => id !== clicked_id));
    } else {
      setSelectedVariants((ids) => [...ids, clicked_id]);
    }
  }

  const updateProductDetails = async() => {
    const storeId = store.id;
    const productId = product.id.replace('gid://shopify/Product/', '');

    const payload = {
      title,
      body_html: description,
      status,
      variants: variants.filter((variant) => selectedVariants.some((id) => id === variant.id)),
    }

    dispatch({
      type: updateStoreProductDetails.type,
      payload: {
        storeId,
        productId,
        data: payload,
      }
    });
  }

  useEffect(() => {
    if (isUpdatingStoreProductDetailsSuccess) {
      setIsProductsReloadRequired(true);
      handleProductResponse(StoreProductDetails);
    } else if (isUpdatingStoreProductDetailsFailed) {
      Swal.fire({
        icon: 'error',
        text: 'Unable to update product details',
        confirmButtonText: 'Retry',
      }).then(async(result) => {
        if (result.isConfirmed) updateProductDetails();
      })
    }
  }, [isUpdatingStoreProductDetailsSuccess, isUpdatingStoreProductDetailsFailed, StoreProductDetails])

  const handleSave = async() => {
    Swal.fire({
      title: 'Are you sure?',
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: 'Confirm'
    }).then(async(result) => {
      if (result.isConfirmed) {
        Swal.fire({
          icon: 'info',
          text: "Thank you for confirming your changes! We're now processing your request and updating your store. Please note that this may take a few minutes to complete.",
        })

        updateProductDetails();
      }
    });
  }

  const handleDelete = async() => {
    Swal.fire({
      title: 'Are you sure?',
      html: '<div>By continuing you will permanently remove this product. <br/>This action cannot be undone.</div>',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Confirm'
    }).then(async(result) => {
      if (result.isConfirmed) {
        const productIds = [product.id.replace('gid://shopify/Product/', '')];
        const data = {
          storeId: store.id,
          productIds,
        }
        dispatch({
          type: bulkDeleteStoreProducts.type,
          payload: data,
        });
      }
    })
  }

  useEffect(() => {
    if (isBulkDeletingStoreProductsSuccess) {
      setIsProductsReloadRequired(true);
      setShowProductDetail(false)
    } else if (isBulkDeletingStoreProductsFailed) {
      Swal.fire({
        icon: 'error',
        text: 'Something went wrong, please try again later.',
      })
    }
  }, [isBulkDeletingStoreProductsSuccess, isBulkDeletingStoreProductsFailed])

  const updateProductStatus = async(status) => {
    const storeId = store.id;
    const productId = product.id.replace('gid://shopify/Product/', '');

    const payload = {
      status,
    }

    dispatch({
      type: updateStoreProductDetails.type,
      payload: {
        storeId,
        productId,
        data: payload,
      }
    });
  }

  // Handle Image Update: start
  const [cropModalOpen, setCropModalOpen] = React.useState(false);
  const [cropper, setCropper] = React.useState(<any />);
  const [picture, setPicture] = React.useState("");

  const getCropData = () => {
    if (typeof cropper !== "undefined") {
      const base64data = cropper.getCroppedCanvas().toDataURL();
      const refinedData = base64data.slice(22);
      updateProductImage(refinedData);
      setCropModalOpen(false);
    }
  };
  const openCropTool = (imgUri) => {
    setPicture(imgUri);
    setCropModalOpen(true);
  }

  const [selectedImage, setSelectedImage] = useState();
  const handleClickImage = async(image) => {
    setSelectedImage(image);
    
    Swal.fire({
      // title: 'Update Product Image',
      html: `<img src="${image.src}" alt="${image.src}" style="background: #fff" />`,
      confirmButtonText: image.is_edited ? 'Undo' : 'Edit',
      confirmButtonColor: '#5757FF',
      showDenyButton: true,
      denyButtonText: `Replace`,
      denyButtonColor: '#5757FF',
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {//Edit feature
        if (image.is_edited) {
          undoProductImage(image);
        } else {
          openCropTool(image.src);
        }

      } else if (result.isDenied) {//Replace feature
        if (image.is_edited) {
          Swal.fire({
            // title: 'Are you sure?',
            html: '<div>We noticed you have already edited your image. By replacing it you will overwrite. <br/>Are you sure you wish to continue?</div>',
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
          }).then(async(result) => {
            if (result.isConfirmed) {
              setShowReplaceImageModal(true);
            }
          });
        } else {
          setShowReplaceImageModal(true);
        }
      }
    })
  }

  const undoProductImage = async(undoImage) => {
    dispatch({
      type: undoStoreProductImage.type,
      payload: {
        storeId: store.id,
        productId: productDetail.id,
        imageId: undoImage.id,
      }
    });
  }

  useEffect(() => {
    if (isUndoingStoreProductImageSuccess) {
      const newImageUrl = UndoStoreProductImageResponse.data.src;

      setProductDetail({
        ...productDetail,
        images: productDetail.images.map((image) => {
          if (image.id === selectedImage.id) {
            return {
              ...image,
              src: newImageUrl,
              is_edited: false,
            }
          }
          return image;
        })
      });
      setIsProductsReloadRequired(true);
    } else if (isUndoingStoreProductImageFailed) {
      Swal.fire({
        icon: 'error',
        text: 'Something went wrong, please try again later.',
      })
    }
  }, [isUndoingStoreProductImageSuccess, isUndoingStoreProductImageFailed, UndoStoreProductImageResponse])

  const updateProductImage = async(base64data) => {
    const payload = { attachment: base64data, src: selectedImage.src };
    dispatch({
      type: updateStoreProductImage.type,
      payload: {
        storeId: store.id,
        productId: productDetail.id,
        imageId: selectedImage.id,
        data: payload,
      }
    });
  }

  useEffect(() => {
    if (isUpdatingStoreProductImageSuccess) {
      const newImageUrl = UpdateStoreProductImageResponse.data.src;

      setProductDetail({
        ...productDetail,
        images: productDetail.images.map((image) => {
          if (image.id === selectedImage.id) {
            return {
              ...image,
              src: newImageUrl,
              is_edited: true,
            }
          }
          return image;
        })
      });
      setIsProductsReloadRequired(true);
    } else if (isUpdatingStoreProductImageFailed) {
      Swal.fire({
        icon: 'error',
        text: 'Something went wrong, please try again later.',
      })
    }
  }, [isUpdatingStoreProductImageSuccess, isUpdatingStoreProductImageFailed, UpdateStoreProductImageResponse])

  const [showReplaceImageModal, setShowReplaceImageModal] = useState(false);
  const handleCloseReplaceModal = () => {
    setShowReplaceImageModal(false);
  }

  const handleUploadNewImage = (imgUri) => {
    if (!imgUri) return;

    setShowReplaceImageModal(false);
    openCropTool(imgUri);
  }
  // Handle Image Update: end

  // Bulk edit: start
  const [scrollPosition, setScrollPosition] = useState(0);
  const [openBulkEditModal, setOpenBulkEditModal] = useState(false);
  const bulkEdit = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);

    setOpenBulkEditModal(true);
  }
  const handleCloseBulkEdit = () => {
    setTimeout(() => {
      window.scrollTo(0, scrollPosition);
    }, 10);
    

    setOpenBulkEditModal(false);
  }
  const handleConfirmBulkEdit = (value, mode) => {
    setTimeout(() => {
      window.scrollTo(0, scrollPosition);
    }, 10);

    setOpenBulkEditModal(false);

    if (mode === 'percent') {
      setVariants(variants.map((v) => {
        const markup = parseFloat(value || 0);
        const price = parseFloat(v.cost) * (1 + markup/100);
        const profit = Math.ceil(price) - parseFloat(v.cost);
        return {
          ...v,
          markup: value,
          price: Math.ceil(price).toFixed(2),
          profit: profit.toFixed(2),
        }
      }))
    } else {
      setVariants(variants.map((v) => {
        const profit = parseFloat(value || 0);
        const price = parseFloat(v.cost) + profit;
        const markup = profit / parseFloat(v.cost) * 100;
        return {
          ...v,
          markup: markup.toFixed(0),
          price: Math.ceil(price).toFixed(2),
          profit: value,
        }
      }))
    }
  }
  // Bulk edit: end

  return (
    <div className="product-details-form">
      <div className="breadcrumb-wrap">
        <div className="breadcrumb">
          <ul>
            <li><a href="#" onClick={() => setPageState('collection-list')}>Manage Collections</a></li>
            <li><a href="#" onClick={() => setShowProductDetail(false)}>{collection.node.title}</a></li>
            <li>{product.title}</li>
          </ul>
        </div>

        <div className="check-options">
          <input id="checkbox" name="checkbox" type="checkbox" className="styled" onChange={(e) => handleChangeStatus(e)} checked={status === 'active'} />
          <label htmlFor="checkbox">Checkbox</label>
        </div>
      </div>

      {isLoading && (
        <img src={withPrefix("assets/img/green-little-balls.gif")} alt="loading" style={{ objectFit: 'contain', width: '100%', height: '400px' }} />
      )}

      {!isLoading && productDetail && (
        <>
          <div className="product-slider">
            <h3>Product Images:</h3>

            <OwlCarousel
              className="owl-carousel owl-theme"
              id="owl-collection-product"
              options={options}
            >
              {productDetail?.images.map((image, index) => (
                <div className="item" key={index} onClick={() => handleClickImage(image)}>
                  <a href="#" className="box">
                    <div className="figure">
                      <img src={image.src} alt={image.src} style={{ background: '#fff' }} />
                    </div>
                    <p>{}</p>
                  </a>
                </div>
              ))}
            </OwlCarousel>
          </div>

          <div className="store-details alt">
            <div className="form-field">
              <form action="#" method="post">
                <h3>Edit Details</h3>

                <ul>
                  <li>
                    <label>Title</label>
                    <div className="input-with-txt">
                      <input id="p-name2" name="p-name2" type="text" value={title} onChange={(e) => setTitle(e.target.value)} placeholder="James Peter Henry" />
                      <div className="max-char">Max Char. 25</div>
                    </div>
                  </li>
                  <li>
                    <label>Description</label>
                    <div className="input-with-txt">
                      <textarea id="message2" name="message2"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)} 
                        placeholder="This medium size backpack is just what you need for daily use or sports activities! The pockets (including one for your laptop) give plenty of room for all your necessities, while the water-resistant material will protect them from the weather. • Made from 100% polyester • Dimensions: H 16⅞&rdquo; (42cm), W 12¼&rdquo; (31 cm), D 3⅞&rdquo; (10cm) • 9.56 oz/yd? (325 g/m?), weight may vary by 5% • Maximum weight limit: 44lbs (20kg) • Water-resistant material • Large inside pocket with a separate compartment for a 15&rdquo; laptop, front pocket with a zipper, and a hidden pocket with zipper on the back of the bag • Top zipper has 2 sliders with zipper pullers • Silky lining, piped inside hems, and a soft mesh back • Padded ergonomic bag straps from polyester with plastic strap regulators • Blank product components sourced from China"></textarea>
                      <div className="max-char">Max Char. 255</div>
                    </div>
                  </li>
                </ul>

                <div className="size-variant">
                  <label>Variants</label>
                  <div className="size">
                    <ul>
                      {variants.map((variant, index) => (
                        <li key={index}>
                          <Tooltip title={variant.title} placement="top-start">
                            <a href="#" onClick={(e) => {handleVariantChange(e, variant.id)}} className={selectedVariants.includes(variant.id) ? "selected" : ""} key={index}>{variant.size.length > 7 ? (index+1) : variant.size}</a>
                          </Tooltip>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </form> 
            </div>
          </div>

          <div className="markups-details">
            <div className="heading">
              <h3 style={{ padding: 0, margin: 0 }}>Markups:</h3>
              <h4 style={{ padding: 0, margin: 0 }} onClick={bulkEdit}>Bulk Edit</h4>
            </div>

            {variants.map((variant, index) => {
              if (selectedVariants.some((id) => id === variant.id)) {
                return (
                  <div className="row-wrap" key={index}>
                    <Tooltip title={variant.title} placement="top-start">
                      <div className="size-col">{variant.size.length > 7 ? (index+1) : variant.size}</div>
                    </Tooltip>
                    <div className="input-col">
                      <div className="items-wrap">
                        <div className="item">
                          <label htmlFor={`${index}Cost`}>Cost:</label>
                          <input name={`${index}Cost`} id={`${index}Cost`} type="text" value={`$${variant.cost}`} disabled/>
                        </div>

                        <div className="item">
                          <label htmlFor={`${index}Markup`}>Markup %</label>
                          <input name={`${index}Markup`} id={`${index}Markup`} type="text" className="bg-white" value={variant.markup} 
                            onChange={(e) => {
                              const re = /^[0-9.()]*$/;

                              if (e.target.value === '' || re.test(e.target.value)) {
                                setVariants(variants.map((v) => {
                                  if (v.title === variant.title) {
                                    const markup = parseFloat(e.target.value || 0);
                                    const price = parseFloat(v.cost) * (1 + markup/100);
                                    const profit = Math.ceil(price) - parseFloat(v.cost);
                                    return {
                                      ...v,
                                      markup: e.target.value,
                                      price: Math.ceil(price).toFixed(2),
                                      profit: profit.toFixed(2),
                                    }
                                  }
                                  return v;
                                }))
                              }
                            }}
                          />
                        </div>

                        <div className="item">
                          <label htmlFor={`${index}Price`}>Price</label>
                          <input name={`${index}Price`} id={`${index}Price`} type="text" value={`$${variant.price}`} disabled/>
                        </div>

                        <div className="item">
                          <label htmlFor={`${index}Profit`}>Profit $</label>
                          <input name={`${index}Profit`} id={`${index}Profit`} type="text" className="bg-white" value={variant.profit}  
                            onChange={(e) => {
                              const re = /^[0-9.()]*$/;

                              if (e.target.value === '' || re.test(e.target.value)) {
                                setVariants(variants.map((v) => {
                                  if (v.title === variant.title) {
                                    const profit = parseFloat(e.target.value || 0);
                                    const price = parseFloat(v.cost) + profit;
                                    const markup = profit / parseFloat(v.cost) * 100;
                                    return {
                                      ...v,
                                      markup: markup.toFixed(0),
                                      price: Math.ceil(price).toFixed(2),
                                      profit: e.target.value,
                                    }
                                  }
                                  return v;
                                }))
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>                        
                )
              }
            })}

          </div>

          <div className="btn-save-wrap">
            <a href="#" className="btn btn-blue" onClick={handleSave}>SAVE</a>
            <a href="#" className="btn btn-red" onClick={handleDelete}>DELETE</a>
          </div>
        </>
      )}

      <ReplaceImageModal
        open={showReplaceImageModal}
        handleClose={handleCloseReplaceModal}
        handleConfirm={handleUploadNewImage}
      />     

      <CropImageModal 
        cropModalOpen={cropModalOpen} 
        setCropModalOpen={setCropModalOpen} 
        picture={picture}
        cropper={cropper} 
        setCropper={setCropper}
        getCropData={getCropData}
      />

      <BulkEditModal
        open={openBulkEditModal}
        handleClose={handleCloseBulkEdit}
        handleConfirm={handleConfirmBulkEdit}
      />
    </div>
  )
}

export const BulkEditModal = ({ open, handleClose, handleConfirm }) => {
  const [mode, setMode] = useState('percent');
  const [value, setValue] = useState('');

  const changeMode = () => {
    if (mode === 'percent') setMode('fixed')
    else setMode('percent');
  }

  const handleValue = (e) => {
    const re = /^[0-9.()]*$/;

    if (e.target.value === '' || re.test(e.target.value)) {
      setValue(e.target.value);
    }
  }

  const handleConfirmModal = () => {
    if (!isLoggedIn()) {
      navigate("/signin");
    } else {
      handleConfirm(value, mode);
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="bulk-edit-modal-title"
      aria-describedby="bulk-edit-modal-description"
      PaperProps={{ style: { pointerEvents: 'auto', borderRadius: '15px', width: 512 } }}
    >
      <div className="bulk-edit-modal">
        <div className="box">
          <div className="box-inn">
            <div className="close" data-bs-dismiss="modal" onClick={handleClose}>
              <img src={withPrefix("assets/img/icon-close-black.svg")} alt="icon-close-white" />
            </div>

            <p className="head">Bulk Edit</p>
            <p className="description">Enter value to apply to all markups</p>

            <div className="mode" onClick={changeMode}>{mode === 'percent' ? 'fixed' : 'percent'}</div>

            <div className="input-box">
              <input id="bulkEditValue" value={value} onChange={handleValue} name="bulkEditValue" type="text" placeholder="Enter value" />
              {mode === 'percent' && <div className="surfix">%</div>}
            </div>
          </div>
        </div>  

        <div className="modal-actions">
          <button type="button" onClick={handleConfirmModal} className="confirm">APPLY TO ALL</button>   
        </div>
      </div>
    </Dialog>        
  )
}
