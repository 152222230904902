import React, { useState } from "react";
import { withPrefix, navigate } from "gatsby";
import Dialog from '@mui/material/Dialog';
import { useMediaQuery } from "@mui/material";

export default function InviteStoreModal() {
  const matchesMobile = useMediaQuery("(max-width:546px)");
  const [open, setOpen] = useState(true);
  const [modalState, setModalState] = useState('first');
  const [value, setValue] = useState('');

  const handleClose = () => {
    setOpen(false);
  }

  const goback = () => {
    navigate('/beta');
  }

  const handleCreateStore = () => {
    setModalState('second');
  }

  const handleSubmit = () => {
    navigate('/beta');
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="bulk-edit-modal-title"
      aria-describedby="bulk-edit-modal-description"
      PaperProps={{ style: { pointerEvents: 'auto', borderRadius: '15px', width: 812, maxWidth: 812 } }}
    >
      <div className="bulk-edit-modal" style={{ padding: '8px' }}>
        <div className="box">
          <div className="box-inn">
            <div className="close" data-bs-dismiss="modal" onClick={handleClose}>
              <img src={withPrefix("assets/img/icon-close-black.svg")} alt="icon-close-white" />
            </div>

            <h6 style={{ fontSize: '16px', fontWeight: '600', color: '#595A5C' }}>
              {modalState === 'first' ? 'Hello Friend,' : 'We’re glad you’re here.'}
            </h6>
            
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '36px' }}>
              <h1 style={{ fontSize: matchesMobile ? '24px' : '38px', color: '#595A5C', margin: 0, lineHeight: 'unset' }}>
                {modalState === 'first' ? 'Looks like you don’t have any storefronts yet.' : 'At this time, 99Gens Storefronts are invite-only.'}
              </h1>
              <img src={withPrefix("assets/img/icon-shop.svg")} alt="icon-shop" style={{ marginLeft: '32px', marginRight: '16px' }} />
            </div>

            {modalState === 'second' && (
              <div className="input-box" style={{ margin: '32px 0 24px' }}>
                <input 
                  id="bulkEditValue" 
                  value={value}
                  onChange={(e) => setValue(e.target.value)} 
                  name="bulkEditValue" 
                  type="text" 
                  placeholder="Enter your e-mail to be added to the waitlist" 
                  style={{ 
                    border: '1px solid #adadad', 
                    background: 'transparent', 
                    fontSize: matchesMobile ? '24px' : '30px', 
                    padding: matchesMobile ? '40px 24px' : '62px 32px', 
                    fontWeight: 'normal', 
                  }}
                />
              </div>
            )}
          </div>
        </div>

        <div className="modal-actions" style={{ justifyContent: 'flex-start', marginTop: '36px' }}>
        {modalState === 'first' ? (
          <>
            <a href="#" onClick={goback} style={{ fontSize: '16px', marginRight: '24px', textDecoration: 'none', color: '#595A5C' }}>Go back</a>
            <button type="button" onClick={handleCreateStore} className="confirm" 
              style={{ width: 'unset', height: matchesMobile ? 60 : 88, display: 'flex', alignItems: 'center' }}>
              <span style={{ fontSize: matchesMobile ? '16px' : '22px', marginRight: '24px' }}>CREATE STOREFRONT</span>
              <img src={withPrefix("assets/img/icon-arrow-right.svg")} alt="icon-arrow-right" />
            </button>
          </>
        ) : (
          <button type="button" onClick={handleSubmit} className="confirm" style={{ height: matchesMobile ? 60 : 88 }}>
            <span style={{ fontSize: matchesMobile ? '16px' : '22px' }}>SUBMIT</span>
          </button> 
        )}
        </div>
      </div>
    </Dialog>
  )
}
