import React, { useEffect, useState } from "react";
import Header from "../components/header";
import Footer1 from "../components/footer-1";
import { isLoggedIn } from "../services/auth";
import { navigate, withPrefix } from "gatsby";
import "../components/my-storefronts/my-storefronts.scss";
import StorefrontList from "../components/my-storefronts/storefront-list";
import DetailsContent from "../components/my-storefronts/details";
import CollectionsContent from "../components/my-storefronts/collections";
import DomainManagementContent from "../components/my-storefronts/domain-management";
import InviteStoreModal from "../components/my-storefronts/invite-store";
import { useDispatch, useSelector } from 'react-redux';
import { getUserStores, selectStoresState } from "../sagas/stores/storesSlice";

export default function MyStorefronts() {
  const dispatch = useDispatch();
  const { UserStores, isFetchingStoresSuccess } = useSelector(selectStoresState);

  const [stores, setStores] = useState([]);
  const [selectedStore, setSelectedStore] = useState();

  useEffect(() => {
    if (!isLoggedIn()) {
      navigate("/signin");
    }
  }, []);

  const [isStoresReloadRequired, setIsStoresReloadRequired] = useState(true);
  useEffect(() => {
    if (isStoresReloadRequired) {
      dispatch(getUserStores());
    }
  }, [isStoresReloadRequired]);

  useEffect(() => {
    if (isFetchingStoresSuccess) {
      setStores(UserStores);
      !!UserStores?.length && setSelectedStore(UserStores[0]);

      setIsStoresReloadRequired(false);
    }
  }, [UserStores, isFetchingStoresSuccess])

  const [collectonsTabClicked, setCollectionsTabClicked] = useState(0);
  const handleCollectionsTab = () => {
    setCollectionsTabClicked(collectonsTabClicked+1);
  }

  return (
    <div className="container-main" id="my-storefronts-page">
      <Header></Header>

      <main className="content-main">
        <div className="store-product-tabs">
          <div className="container">
            <div className="row-box">
              <div className="left-cl">
                <StorefrontList stores={stores} selectedStore={selectedStore} setSelectedStore={setSelectedStore} />
              </div>

              <div className="right-cl">
                <div className="tab-links-wrap">
                  <div className="tab-links3">
                    <ul className="nav">
                      <li><a href="#collections" data-bs-toggle="tab" className="active" onClick={handleCollectionsTab}>Collections</a></li>
                      <li><a href="#details" data-bs-toggle="tab">Details</a></li>
                      <li><a href="#domain-management" data-bs-toggle="tab">Domain Management</a></li>
                    </ul>
                  </div>

                  <div className="profile">
                    <div className="icon">
                      <img src={withPrefix("assets/img/icon-user.png")} alt="icon-user" />
                    </div>
                    <div className="name">{selectedStore?.store_title ?? ''}</div>
                  </div>
                </div>
                
                <div className="tab-content">
                  <div className="tab-pane active" id="collections">
                    <CollectionsContent store={selectedStore} tabClicked={collectonsTabClicked} />
                  </div>

                  <div className="tab-pane" id="details">
                    {selectedStore && <DetailsContent store={selectedStore} setIsStoresReloadRequired={setIsStoresReloadRequired} />}
                  </div>

                  <div className="tab-pane" id="domain-management">
                    {selectedStore && <DomainManagementContent store={selectedStore} />}
                  </div>
								</div>

                {isFetchingStoresSuccess && UserStores.length === 0 && (
                  <InviteStoreModal />
                )}
							</div>
            </div>
          </div>
        </div>
      </main>

      <Footer1></Footer1>
    </div>
  );
}
